import React from 'react'
import Page from '../Layout/Page'
import Wave from '../component/Wave'
import { graphql } from 'gatsby'
import * as style from '../css/contact.module.scss'
import Avatar from '../component/Avatar'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
function Waiting() {
  return <i className='fas fa-spinner fa-spin' />
}

function CustomErrorMessage({ children }) {
  return <div style={{ color: 'red', fontSize: '1rem' }}>{children}</div>
}

// function Contact({ element }) {

//     axios
//       .post(getUrl('contact'), {
//         name: name,
//         last: last,
//         email: email,
//         phone: phone,
//         message: message,
//       })
//       .then(res => {
//         Swal.fire({
//           title: 'Thank you',
//           text: 'I will contact you back soon!',
//           icon: 'success',
//         })
//         setSending(false)
//       })
//       .catch(err => {

//         setSending(false)
//       })
//   }
// }

const ContactSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string().min(10, 'Too Short!').max(5000, 'Too Long!').required('Required'),
})

const initialValues = { name: '', email: '', message: '' }

const contactData = {
  nav: { nextfg: '#ffffff', title: 'You need my help ?', nextbg: '#ffffff' },
  nextbg: '#363636',
  nextfg: '#1a1a1a',
  items: [
    { label: 'Fullname', id: 'name', type: 'input' },
    { label: 'Email', id: 'email', type: 'input' },
    { label: 'Message', id: 'message', type: 'textarea' },
  ],
}

function Contact({
  data: {
    craped: {
      childrenImageSharp: [craped],
    },
  },
}) {
  const sendForm = async values => {
    return new Promise(r => setTimeout(r, 500)).then(() => {
      Swal.fire({
        title: "I'm sorry",
        text: "You can't contact me with this form please send me a message on LinkedIn or Facebook",
        icon: 'error',
      })
    })
  }
  return (
    <Page selected='Contact' title='Contact me' description='Contact Ahmed Meftah'>
      <div className={style.contact}>
        <section className={style.banner}>
          <div className={style.container + ' container bordred'}>
            <Avatar img={craped} />
            <div className={style.presentation}>
              <h1>{contactData.nav.title}</h1>
            </div>
          </div>
          <Wave fg={contactData.nav.nextfg} bg={contactData.nav.nextbg} />
        </section>
        <div className='container'>
          <Formik validationSchema={ContactSchema} initialValues={initialValues} onSubmit={sendForm}>
            {({ isSubmitting, errors, touched }) => (
              <Form>
                {contactData.items.map(({ label, id, type }) => (
                  <div
                    key={id}
                    className={errors[id] && touched[id] ? style.invalid + ' ' + style.formGroup : style.formGroup}
                  >
                    <label htmlFor={id}>{label}</label>
                    <div>
                      <ErrorMessage name={id} component={CustomErrorMessage} />
                      <Field id={id} name={id} placeholder={label} as={type} />
                    </div>
                  </div>
                ))}
                <div className='form-group'>
                  <div></div>
                  <button type='submit' disabled={isSubmitting}>
                    {isSubmitting ? <Waiting /> : 'Send'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Wave fg={contactData.nextfg} bg={contactData.nextbg} />
      </div>
    </Page>
  )
}

export const querry = graphql`
  query {
    craped: file(relativePath: { eq: "craped.jpg" }) {
      childrenImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
  }
`

export default Contact
